@import '~antd/dist/antd.css';
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Russo+One&family=Work+Sans:wght@100;300;400;700&display=swap');


* {
  font-family: 'Work Sans', sans-serif !important;
}

body {
  background-color: #F8F8F8;
}

.ant-layout {
  background-color: #F8F8F8;
}

a {
  color: #8666FF !important;
}

a:hover {
  color: #7458da;
  text-decoration: underline;
}

.primary {
  color: #8666FF;
}

.primarybck {
  background-color: #c7baf6 !important;
}

.primarybck:hover {
  background-color: #7e71ac !important;
}

.russo {
  font-family: 'Russo One', sans-serif !important;
}

.wsans {
  font-family: 'Work Sans', sans-serif;
}

.dmsans {
  font-family: 'DM Sans', sans-serif;
}

.p {
  font-family: 'Work Sans', sans-serif !important;
}

.cLabel {

  font-size: 14px;
  color: #4b4b4b;
  font-family: 'DM Sans', sans-serif !important;
  font-weight: 500;
}

.labelRequired::before {
  content: "*";
  color: red;
  font-size: 10px;
  font-weight: 700;
  margin-right: 5px;
}

.clickable {
  cursor: pointer;
}

/*LAYOUTS*/
.layoutContent {
  padding: 50px 0px;
  background-color: #F8F8F8;
}

.layoutContentWithFixedHeader {
  margin-top: 75px;
}

.fixed-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  padding: 0;
}

.ant-layout-header {
  background-color: #fff;
  box-shadow: none !important;
  height: 75px !important;
}

.ant-layout-sider-children {
  width: 100%;
}

.sider {
  background-color: white;
  box-shadow: 0px 0px 10px rgba(111, 111, 111, 0.1);
  left: 0;
  top: 0;
  border-radius: 15px;
  width: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px 0px;
  overflow: auto;
}

.ant-menu:not(.ant-menu-horizontal) {
  font-family: 'DM Sans', sans-serif;
}

.ant-menu-item:hover .ant-menu-title-content,
.ant-menu-item:hover .anticon {
  color: #8666FF;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #EFECFE;
  color: #8666FF;

}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-left: 3px solid #8666FF;
  height: 95%;
  top: 50%;
  transform: translateY(-50%);
  border-right: 0px solid;
  border-radius: 2px;
  left: 0;
}


/*BUTTONS*/
.ant-btn {
  height: 35px;
  border-radius: 10px;
}

.ant-btn-link {
  color: #8666FF !important;
}

.ant-btn-primary {
  background-color: #8666FF;
  border-color: #8666FF;
}

.ant-btn-primary:focus {
  border-color: #8666FF;
  background-color: #8666FF;
}

.ant-btn-primary:hover {
  background-color: #7458da;
  border-color: #7458da;
}

.ant-btn-ghost:hover {

  color: #7458da;
  border-color: #7458da;
}

.ant-btn-default:hover,
.ant-btn-default:focus,
.ant-btn-default:active {

  color: #7458da;
  border-color: #7458da;
}

.ant-btn-sm {
  height: 30px;
  font-size: 12px;
  border-radius: 10px;
}

/*DISPLAY*/

.full-h {
  height: 100vh;
}

.full-w {
  width: 100vw;
}

.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-center {
  justify-content: center !important;
}

.flex-end {
  justify-content: flex-end !important;
}

.flex-start {
  justify-content: flex-start !important;
}

/*TYPOGRAPHY*/
.strong {
  font-weight: bold;
}

.ant-typography {
  height: auto;
  line-height: 1.5;
  font-family: 'Work Sans', sans-serif;
}

.bodyHeader {
  font-size: 16px;
}

.bodyDetails {
  font-size: 13px;
}

/*ELEMENTS*/

.pillAccount {

  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: auto;

}



.customCircledTag {
  border-radius: 50%;
  background-color: #F9F9F9;
  color: #4f4f4f;
  font-size: 12px;
  font-weight: bold;
  padding: 2px 5px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  cursor: pointer;
}

.customCircledTag:hover {
  color: #8666FF;
  border: 1px solid #8666FF;
}

.customTag {
  background-color: #F9F9F9;
  padding: 10px 15px;
  border-radius: 10px;
  color: #4f4f4f;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  cursor: pointer;
}

.customTag:hover {
  background-color: #EFECFE;
}

.customTag:hover>* {
  color: #8666FF;
}

.customTagActive {
  background-color: #EFECFE;
}

.pill {
  background-color: white;
  box-shadow: 0px 0px 5px rgba(183, 154, 245, 0.1);
  padding: 5px 15px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  cursor: pointer;
}

button:focus-visible,
.ant-modal-close-x:focus-visible {
  border: none !important;
}


h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'DM Sans', sans-serif !important;
  /*SET FONT WEIGHT TO 700*/
  font-weight: 700 !important;
}


.pageHeaderHelperContainer {
  background-color: #EFEFEF;
  padding: 15px 25px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;

}

.actionableHeaderContainer {
  padding: 20px 25px;
  background-color: white;
  border-radius: 10px;
}


.tableContainer {
  padding: 20px 20px;
  background-color: white;
  border-radius: 10px;
}

.pro-table table th,
th::before {
  background-color: white !important;
}


.ant-pagination-item-active {
  border: 1px solid #876ee4;
}

.ant-pagination-item-active>* {
  color: #876ee4 !important;
}

.ant-typography-edit .anticon-edit {
  color: #876ee4 !important;
}



/*AUTH*/
.authLeftside {
  min-height: 100vh;
  overflow: auto;
  height: auto;
  max-width: 100%;
  background-color: #F8F8F8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;

}

.authholder {
  top: 10;
  padding: 10px;
  background-color: #fff;
  min-height: 90%;
  height: auto;
  border-radius: 20px;
  box-shadow: 0px 5px 10px rgba(111, 111, 111, 0.1);

}

.authRightSide {
  background-color: #F2F2F2;
  min-height: 100vh;
  height: auto;
  width: 100%;
  position: fixed;

}

.authRightHolder {
  position: fixed;
  height: 60%;
  background-color: white;
  bottom: 0;
  right: 0;
  width: 40%;
  border-top: 15px solid black;
  border-left: 15px solid black;
  border-top-left-radius: 25px;
  padding: 5% 0px 5% 40px;
}

.socialLoginButton {
  border: 1px solid #E7E7E7;
  width: 90%;
  margin-top: 7px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  padding: 5px 0px;
  padding-left: 32%;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(150, 150, 150, 0.07);
  cursor: pointer;
}




/*INPUTS*/
.ant-input-affix-wrapper {
  border: none !important;
  border-radius: 10px !important;
}

.ant-input-password {

  padding: 0 10px;
  border: 0.5px solid #E7E7E7 !important;
}

.ant-input,
.ant-input-password {
  border: 0.5px solid #E7E7E7;
  min-height: 38px;
  border-radius: 10px;
}

.ant-form-item-label label {
  font-size: 14px;
  color: #4b4b4b;
  font-family: 'DM Sans', sans-serif !important;
  font-weight: 500;
}

input::placeholder {
  font-family: 'Work Sans', sans-serif !important;
}

input {
  font-family: 'Work Sans', sans-serif !important;
}

input:active {
  font-family: 'Work Sans', sans-serif !important;
  color: #333 !important;
}

.ant-input:focus {
  border: 0.5px solid #876ee450;
  color: #333 !important;
}

.ant-input:hover {
  border: 0.5px solid #876ee450;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #876ee4;
  border-color: #876ee4;
}

.ant-drawer-content {
  border-radius: 20px !important;

}

.hoverPrimaryBg:hover {
  background-color: #eeecfd;
  cursor: pointer;
}

.integration-card--disabled {
  background-color: #f0f0f0;
  cursor: not-allowed;
}

.integration-card--disabled:hover {
  background-color: #e5e5e5 !important;
  cursor: not-allowed;
}

.lastTableRow {
  vertical-align: top;
  height: 120px;
  overflow-y: scroll;
}

.lastTableRowMax {
  vertical-align: top;
  height: 190px;
  overflow-y: scroll;
}

.hoverBlack:hover {
  color: rgb(23, 23, 23) !important;
  cursor: pointer;
}

.hoverPrimary:hover {
  color: #876ee4 !important;
}

.hoverDanger:hover {
  color: #ff4d4d !important;
}

.hoverBorderPrimary {
  border: 1px solid transparent !important;
}

.hoverBorderPrimary:hover {
  border: 1px solid #876ee4 !important;
}


.danger {
  color: #ff4d4d;
}

.ant-modal-content {
  border-radius: 20px;
}


.ant-tag {
  border-radius: 10px !important;
}

.ant-switch-checked {
  background-color: #876ee4 !important;
}


.clientSeclectRow {
  border: 1px solid #f1f1f1;
  min-width: 100%;
  padding: 7px 15px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.customAvatar {
  border-radius: 50%;
  padding: auto;
  height: 30px;
  width: 30px;
  background-color: #f7f7f7;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-tabs-nav::before {
  border-bottom: none !important;
}

.ant-tabs-tab {
  background-color: #fafbfd !important;
  border: none !important;
  margin-right: 5px !important;
  border-radius: 10px !important;
  padding: 8px 20px !important;
  font-family: 'Work Sans', sans-serif !important;
}

.ant-tabs-tab-active {
  background-color: #F3EDFF !important;
  border-bottom: none !important;
  font-family: 'Work Sans', sans-serif !important;


}

.ant-tabs-tab:hover {
  color: #876ee4 !important;
  font-family: 'Work Sans', sans-serif !important;
}

.ant-tabs-nav .ant-tabs-tab-active>* {
  color: #876ee4 !important;
  font-family: 'Work Sans', sans-serif !important;
}

.ant-tabs-nav .ant-tabs-tab-active .ant-row .ant-typography {
  color: #876ee4 !important;
  font-family: 'Work Sans', sans-serif !important;
}

.ant-picker {
  border-radius: 10px;
  height: 38px;
}

.ant-picker:hover {
  border-color: #876ee4;
}

.ant-picker-focused {
  border-color: #876ee4;
}

.ant-picker-range .ant-picker-active-bar {
  background: #876ee4;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before {
  background: #F3EDFF;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background: #F3EDFF;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  color: white;
  border: 1px solid #876ee4;


}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: #876ee4;
  color: white;
  border: 1px solid #876ee4;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
  background: #876ee4;

}

.blockedInvoiceTypeCard {
  padding: 10px 15px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  min-height: auto;
  border-radius: 10px;
  border: 1px solid #f7f7f7;
  cursor: not-allowed;
}

.invoiceTypeCard {
  padding: 10px 15px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  min-height: auto;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
  cursor: pointer;
}

.invoiceTypeCard:hover {
  background-color: #fafbfd;
}

.invoiceTypeCardSelected {
  border: 1px solid #876ee4;
}


.settingsContainer {
  padding: 20px 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: auto;
  border-radius: 10px;
  border: 1px solid #ffffff;
  background-color: #ffffff;
  cursor: pointer;
  box-shadow: 2px 2px 20px rgba(169, 169, 169, 0.1);
}

.ant-upload-select-picture-card {
  border-radius: 10px;
  overflow: hidden;
}

.ant-upload:hover {
  border-color: #876ee4 !important;
}


.pricingCard {
  align-items: center;
  background-color: white;
  border-radius: 10px;
  box-shadow: 2px 2px 20px rgba(169, 169, 169, 0.1);
}

.relatedPaymentCard {
  box-shadow: 2px 2px 15px rgba(225, 225, 225, 0.18);
  border-radius: 10px;
}

.relatedPaymentCard:hover {
  box-shadow: 2px 2px 20px rgba(169, 169, 169, 0.1);
}

.activeTag {
  background-color: #876ee4;
  color: white;

}

.ant-tree-node-content-wrapper {
  background-color: transparent !important;
  border-radius: 10px !important;
}

.ant-tree-checkbox-inner {

  border-color: #876ee4 !important;
}

.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background-color: #876ee4 !important;
}

.ant-checkbox-inner {
  border-color: #876ee4 !important;
}

.ant-checkbox-inner::after {
  background-color: #876ee4 !important;
}

.ant-table-filter-trigger>span {
  color: #cecece !important;

}

.ant-btn:hover,
:focus {
  border-color: #876ee4 !important;
  color: #d4cded !important;
}

.ant-table-filter-trigger .active span {
  color: #876ee4 !important;
}

.ant-typography-copy span {
  color: #876ee4 !important;
}

.ant-btn-dangerous:hover {
  border-color: white !important;
  color: white !important;

}


.qrDownloadableContainer:hover .qrDownloadableContainerChild {
  opacity: 1 !important;
  animation: ease-in 2s all !important;
}

.qrDownloadableContainerChild {
  opacity: 0 !important;
  animation: ease-in 2s all !important;
}

.slick-dots li {
  background-color: #cecece;
  border-radius: 5px;
}

.ant-carousel .slick-dots li.slick-active button {
  background-color: #876ee4;
  border-radius: 5px;
}

.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected {
  background-color: #876ee442;
  color: #876ee4;
}

.ant-segmented {
  background-color: #F8F8F8 !important;
  border: none !important;
  height: 35px;
  overflow: hidden;
}

.ant-segmented-item {
  height: 32px;
}

.ant-segmented-item-selected {
  border: none !important;
  box-shadow: none !important;
}

.ant-segmented-item-input {
  color: #876ee4;
}


-CLASS TO REMOVE BORDER TO ANT ITEM .removeBorder .ant-form-item-control-input-content,
.ant-form-item-control,
.ant-form-item-row,
.ant-input {
  border-radius: 10px !important;
  /* border: none !important; */
}

.ant-input-search-button {
  background-color: white;
  border: none;
  box-shadow: none;
  border-radius: 5px;
  color: white;
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
  min-height: 38px;
}

.ant-input-search {
  border-radius: 8px;
  border: 1px solid #E7E7E7;
  background-color: #F8F8F8 !important;
}

.ant-input-search .ant-input {
  border: none !important;
}

.ant-input:active {
  color: black !important;
}

.secondary {
  color: rgba(0, 0, 0, 0.45) !important;

}

.ant-table-row .ant-table-row-level-0 .ant-table-row-selected:hover {
  background: #f8e6ff !important;
  background-color: #f8e6ff !important;
}

.ant-table-tbody>tr.ant-table-row-selected>td {
  /* background: #f8e6ff; */
}

.bestOptCard {
  border: 2px solid #8666FF !important;
  box-shadow: 2px 2px 20px rgba(169, 169, 169, 0.5) !important;
  /*ADD FLAG SAYING BEST OPTION TO CORNER*/

}

.backgroundGrayHoverSettings {
  background-color: 'transparent' !important;
}

.backgroundGrayHoverSettings:hover {
  border-radius: 10px;
  background-color: rgba(213, 213, 213, 0.444);
}

/* ADD ICON IN CENTER OF DIV */

.centerIconSettings {
  opacity: 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  font-size: 35px;
  color: #9788a9;
}

.backgroundGrayHoverSettings:hover .centerIconSettings {
  opacity: 100%;
}

.showInputBorder {
  border: 0.5px solid #cecece !important;
}

.css-26l3qy-menu {
  position: absolute;
  z-index: 9999;
}

.ant-select-selector {
  box-shadow: none !important;
  border: 0.5px solid #E7E7E7 !important;

  background-color: white !important;
  border-radius: 10px !important;


}

.ant-typography-copy {
  display: inline-flex !important;
}

.removeBorder .ant-modal-content {
  border-radius: 10px !important;
  border: none !important;
}

/*MEDIA QUERIES*/
@media only screen and (max-width: 700px) {
  .authholder {
    padding: 20px;
  }

  .socialLoginButton {
    justify-content: center;
    padding: 5px 10px;
  }
}

.shadowOnHover:hover {
  box-shadow: 2px 2px 20px rgba(169, 169, 169, 0.5) !important;
}

.searchModalInput {
  border: 1px solid #f6f6f6 !important
}

.integration-permissions--container {
  background-color: #F5F5F5;
  padding: 20px 40px;
  border-radius: 10px;
}


#HW_badge_cont {
  position: absolute !important;
}


/* INSTANT SEARCH ALGOLIA */

.instant-search--algolia-search-input {
  width: 93%;
  padding: 5px 10px;
  border: 1px solid #E7E7E7;
  border-radius: 10px 0px 0px 10px;
}

.instant-search--algolia-search-input {
  width: 100%;
  padding: 7px 10px;
  border: 1px solid #E7E7E7;
  border-radius: 10px;
}

.instant-search--algolia-search-input:focus {
  outline: none;
}

.instant-search--algolia-search-box-submit {
  display: none;
  background-color: #e5e5e5;
  border: 1px solid #E7E7E7;
  width: 7%;
  padding: 5px 10px;
  border: none;
  border-radius: 0px 10px 10px 0px;
  color: white;
}

.instant-search--algolia-search-box-submit:focus {
  outline: none;
  border: 1px solid #7e71ac;
}

.instant-search--algolia-search-box-reset-icon {
  display: none;
}

.ais-SearchBox-reset {
  display: none;
}

.instant-search--algolia-search-hits-list {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.instant-search--result-item-container {
  margin: 0px 5px;
  padding: 5px;
}

.instant-search--result-item {
  padding: 5px 10px;
}

.instant-search--result-item:hover {
  background-color: #f4f4f4;
  border-radius: 10px;
}

.instant-search--highlight-fid {
  color: #909090;
  font-size: 12px;
}

.instant-search--highlight-client-email {
  color: #555555;
  font-weight: 500;
}

.instant-search--highlight-client-name {
  color: #9680e2;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}